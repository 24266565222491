import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      _showSnackbar: 'snackbar/show',
    }),

    showSnackbar(message, success = true, list = null) {
      this._showSnackbar({
        message: message,
        list: list,
        color: success ? 'success' : 'red',
      })
    },
  },
}
